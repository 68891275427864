// When raising virtual click events we prefer the dispatchEvent method
// over calling "click()" on an element, Firefox was blocking some
// clicks to open in a new tab. This will not work on IE11, but we don't
// support this anyways
//
//   https://stackoverflow.com/a/48367757/929771

export const openInNewTab = (url: string) => {
  const a: any = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.setAttribute('rel', 'noopener noreferrer');
  document.body.appendChild(a);
  a.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  );
  document.body.removeChild(a);
};

/**
 * Determines if the current window is within an iframe
 * in a safe cross browser model.
 *
 * @returns {Boolean} True if in an iframe, else false.
 */
export const isInIFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const ReactNativeWebViewName = 'ReactNativeWebView';

// window.self === window.top in React Native WebView;
export const isInReactNativeWebview = () => {
  try {
    return !!window?.[ReactNativeWebViewName];
  } catch {
    return false;
  }
};

/**
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
 *
 * In FF4, if disabled, window.localStorage should === null.
 *
 * Normally, we could not test that directly and need to do a
 *   `('localStorage' in window)` test first because otherwise Firefox will
 *   throw bugzil.la/365772 if cookies are disabled*
 *
 * Similarly, in Chrome with "Block third-party cookies and site data" enabled,
 * attempting to access `window.sessionStorage` will throw an exception. crbug.com/357625*
 *
 * Also in iOS5 Private Browsing mode, attempting to use localStorage.setItem
 * will throw the exception: QUOTA_EXCEEDED_ERROR DOM Exception 22.
 *
 * Peculiarly, getItem and removeItem calls do not throw.*
 * Because we are forced to try/catch this, we'll go aggressive.*
 *
 * Just FWIW: IE8 Compat mode supports these features completely:
 *   www.quirksmode.org/dom/html5.html
 * But IE8 doesn't support either with local files
 */
export const isLocalStorageEnabled = () => {
  const feature = 'feature:test';
  try {
    localStorage.setItem(feature, feature);
    localStorage.removeItem(feature);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
 *
 * navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
 * configurations. For example, when blocking cookies via the Advanced
 * Privacy Settings in IE9, it always returns true. And there have been
 * issues in the past with site-specific exceptions.
 * Don't rely on it.
 *
 * try..catch because some in situations `document.cookie` is exposed but throws a
 * SecurityError if you try to access it; e.g. documents created from data URIs
 * or in sandboxed iframes (depending on flags/context)
 */
export const isCookiesEnabled = () => {
  try {
    // Create cookie
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  } catch (e) {
    return false;
  }
};

export const isServer = () => {
  const isBrowser =
    typeof window !== 'undefined' &&
    // browser case
    ({}.toString.call(window) === '[object Window]' ||
      // electron case
      {}.toString.call(window) === '[object global]');

  return !isBrowser;
};

export const isLocalHostname = (hostname: string) => {
  const lowerHostname = (hostname ?? '').toLocaleLowerCase().trim();
  return (
    lowerHostname.indexOf('localhost') > -1 ||
    lowerHostname.indexOf('127.0.0.1') > -1
  );
};

export const isLocalhost = () => isLocalHostname(window?.location?.hostname);
