import { Page } from '@komo-tech/core/models/Page';

import { API } from './API';
import { ApiRequestConfig } from './ApiRequestConfig';

export type ApiConfigBuilder = (config: RequestInit) => ApiRequestConfig;

export abstract class ApiBase {
  private readonly _basePath: string;
  private readonly _configBuilder: ApiConfigBuilder;

  protected constructor(
    basePath: string,
    configBuilder: ApiConfigBuilder = undefined
  ) {
    this._basePath = basePath;
    this._configBuilder = configBuilder;
  }

  private buildConfig(config: ApiRequestConfig = {}): ApiRequestConfig {
    return this._configBuilder ? this._configBuilder(config) : config;
  }

  public getAbsolutePath(path: string): string {
    const cleanPath =
      path.startsWith('/') && path.length > 1 ? path.substr(1) : path;
    return this._basePath ? `${this._basePath}/${cleanPath}` : cleanPath;
  }

  public get<T>(path: string, config: ApiRequestConfig = {}): Promise<T> {
    return API.get<T>(this.getAbsolutePath(path), this.buildConfig(config));
  }

  public async getPage<T>(
    path: string,
    itemMap: (x: any) => T,
    config: ApiRequestConfig = {}
  ): Promise<Page<T>> {
    const { items, ...rest } = await API.get<Page<T>>(
      this.getAbsolutePath(path),
      this.buildConfig(config)
    );
    return new Page<T>({ ...rest, items: (items || []).map(itemMap) });
  }

  public async postPage<T>(
    path: string,
    body: any,
    itemMap: (x: any) => T,
    config: ApiRequestConfig = {}
  ): Promise<Page<T>> {
    const { items, ...rest } = await API.post<Page<T>>(
      this.getAbsolutePath(path),
      body,
      this.buildConfig(config)
    );
    return new Page<T>({ ...rest, items: (items || []).map(itemMap) });
  }

  public post<T>(
    path: string,
    body?: any,
    config: ApiRequestConfig = {},
    options?: { sendRawBody?: boolean }
  ): Promise<T> {
    return API.post<T>(
      this.getAbsolutePath(path),
      body,
      this.buildConfig(config),
      options
    );
  }

  public postFormData<T>(
    path: string,
    body?: any,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    return API.postFormData<T>(
      this.getAbsolutePath(path),
      body,
      this.buildConfig(config)
    );
  }

  public canSendBeacon(): boolean {
    return API.canSendBeacon();
  }

  public sendBeacon(path: string, body?: any): boolean {
    return API.sendBeacon(this.getAbsolutePath(path), body);
  }

  public put<T>(
    path: string,
    body?: any,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    return API.put<T>(
      this.getAbsolutePath(path),
      body,
      this.buildConfig(config)
    );
  }

  public delete<T>(
    path: string,
    config: ApiRequestConfig = {}
  ): Promise<T | void> {
    return API.delete<T>(this.getAbsolutePath(path), this.buildConfig(config));
  }
}
